@import "./colors.scss";
@import "./fonts.scss";

$theme-colors: (
  "blue": $blue,
  "pink": $pink,
  "green": $green,
  "grey": $grey,
  "primary": $primary,
  "secondary": $secondary,
);

$linearGradientBlue: linear-gradient(hsla(247, 88%, 63%, 1), hsla(247, 88%, 51%, 1));
$linearGradientPink: linear-gradient(hsla(320, 98%, 63%, 1), hsla(320, 98%, 48%, 1));

@import "~bootstrap/scss/bootstrap";

html, body, #root {
  height: 100%;
}

.full-height-responsive {
  min-height: auto;
}

@include media-breakpoint-up(lg) {
  .full-height-responsive {
    min-height: 100% !important;
  }
}

body {
  font-family: 'Simplon BP', Arial, Helvetica, sans-serif;
}

h2 {
  font-weight: bold;
}

.spacer {
  white-space: pre;
}

.button-full-width {
  width: 100%;
}

.navbar {
  background-color: $blue;
  color: white;

  .navbar-brand {
    a {
      color: white;
    
      &:hover {
        text-decoration: none;
      }

      .logo {
        max-height: 2.5rem;
      }

      .logo-title {
        vertical-align: middle;
      }
    }
  }
}

.project-card {
  .card {
    border: none;
    background: $linearGradientBlue;
    color: white;
    margin: 0.5rem 0;

    &:hover {
      cursor: pointer;
      background: $linearGradientPink;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.job {
  .card-body {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;

    .job-col {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:not(:last-child) {
        border-right: 1px solid #e6e5f1;
      }
    }
  }

  .job-finish-label {
    color: $blue;
    &:hover {
      color: $pink;
    }

    .job-icon-file {
      font-size: 1.8rem;
    }
  
    h5 {
      margin: 0;
      padding: 0.25rem 0;
    }

    .spacer {
      text-decoration: underline;
      color: white;
    }
  }

  .job-pending-label {
    margin: 0;
    padding: 0.25rem 0;
    color: $pink;
    text-align: center;

    .job-icon-file {
      font-size: 1.2rem;
    }
  }
}

#status-col {
  background-color: $grey;
}

#status {
  // padding-left: 1rem;
  // padding-right: 1rem;

  .title {
    border-bottom: 3px solid black;
  }
}

.image-picker {
  .pickable-image {
    display: inline-block;
    margin: 5px;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      filter: grayscale(100%);
    }

    &.pickable-image-selected {
      img {
        outline: 5px solid $pink;
        filter: none;
      }
    }
  }

  .pickable-image-add {
    display: inline-block;
    margin: 5px;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
}

