@font-face {
  font-family: 'Simplon BP';
  src: url('../assets/fonts/SimplonBPRegular.eot');
  src: url('../assets/fonts/SimplonBPRegular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/SimplonBPRegular.woff2') format('woff2'),
      url('../assets/fonts/SimplonBPRegular.woff') format('woff'),
      url('../assets/fonts/SimplonBPRegular.ttf') format('truetype'),
      url('../assets/fonts/SimplonBPRegular.svg#SimplonBPRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Simplon BP';
  src: url('../assets/fonts/SimplonBP-Bold.eot');
  src: url('../assets/fonts/SimplonBP-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/SimplonBP-Bold.woff2') format('woff2'),
      url('../assets/fonts/SimplonBP-Bold.woff') format('woff'),
      url('../assets/fonts/SimplonBP-Bold.ttf') format('truetype'),
      url('../assets/fonts/SimplonBP-Bold.svg#SimplonBP-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Simplon BP';
  src: url('../assets/fonts/SimplonBP-BoldItalic.eot');
  src: url('../assets/fonts/SimplonBP-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/SimplonBP-BoldItalic.woff2') format('woff2'),
      url('../assets/fonts/SimplonBP-BoldItalic.woff') format('woff'),
      url('../assets/fonts/SimplonBP-BoldItalic.ttf') format('truetype'),
      url('../assets/fonts/SimplonBP-BoldItalic.svg#SimplonBP-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Simplon BP';
  src: url('../assets/fonts/SimplonBPRegular-Italic.eot');
  src: url('../assets/fonts/SimplonBPRegular-Italic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/SimplonBPRegular-Italic.woff2') format('woff2'),
      url('../assets/fonts/SimplonBPRegular-Italic.woff') format('woff'),
      url('../assets/fonts/SimplonBPRegular-Italic.ttf') format('truetype'),
      url('../assets/fonts/SimplonBPRegular-Italic.svg#SimplonBPRegular-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}
